import { graphql } from "gatsby";
import React, { useState } from "react";
import { SubEventQuery } from "../../graphql-types";
import { usePageContext } from "../components/context/PageContext";
import { Layout } from "../components/layout/Layout";
import BlockContent from "@sanity/block-content-to-react";
import * as styles from "../styles/pages/subEvent.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { BottomNav } from "../components/layout/BottomNav";
import { SEO } from "../components/seo/seo";
import { BreadCrumb } from "../components/breadcrumb/BreadCrumb";
import { SelectionBlocks } from "../components/blocksContent/SelectionBlocks";

interface Props {
  data: SubEventQuery;
  pageContext: any;
}

const SubEventTemplate: React.FC<Props> = ({ data, pageContext }) => {
  const { lang } = usePageContext();
  const { sanitySubEvent, sanityEventsPage } = data;
  const [opens, setOpens] = useState<number[]>([]);

  return (
    <>
      <SEO rawMeta={sanitySubEvent?._rawMetadata} />
      <div className={styles.wrapper}>
        <BreadCrumb
          label={sanityEventsPage?.data?._rawTitleBreadcrumb?.[lang]}
          labelUrl={"/event"}
          label2={sanitySubEvent?.content?._rawTitle?.[lang]}
        />
        <header>
          <div className="desktopCentered">
            <h2>{sanitySubEvent?.content?._rawTitle?.[lang]}</h2>
            <BlockContent
              blocks={sanitySubEvent?.content?._rawDescription?.[lang]}
            />
          </div>
        </header>
        <section>
          <div className={styles.container}>
            {sanitySubEvent?.content?.items?.map((item, index: number) => (
              <article
                key={`content-${item?._key}`}
                onClick={() =>
                  setOpens(
                    opens.includes(index)
                      ? opens.filter(item => item !== index)
                      : [...opens, index]
                  )
                }
                className={opens.includes(index) ? styles.contentDisplayed : ""}
              >
                <div>
                  <GatsbyImage
                    image={item?.img?.image?.asset?.gatsbyImageData}
                    alt={item?.img?._rawAlt?.[lang]}
                    className={styles.image}
                  />
                  <div className={styles.handle}>
                    <h3 className={styles.title}>{item?._rawTitle?.[lang]}</h3>
                    <FontAwesomeIcon
                      icon={opens.includes(index) ? faChevronUp : faChevronDown}
                      className={styles.icon}
                    />
                  </div>
                </div>
                <div className={styles.content}>
                  {/* <BlockContent blocks={item?._rawDescription?.[lang]} /> */}
                  <SelectionBlocks blocks={item?._rawContent?.[lang]} />
                </div>
              </article>
            ))}
          </div>
        </section>
        <BottomNav
          prev={pageContext.prev}
          next={pageContext.next}
          current={sanitySubEvent?.content?._rawTitle}
        />
      </div>
    </>
  );
};

export const QUERY = graphql`
  query SubEvent($id: String) {
    sanitySubEvent(id: { eq: $id }) {
      _rawMetadata(resolveReferences: { maxDepth: 10 })
      content {
        _rawDescription
        _rawTitle
        img {
          _rawAlt
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        items {
          _key
          _rawDescription
          _rawTitle
          _rawContent
          img {
            _rawAlt
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }

    sanityEventsPage {
      data {
        _rawTitleBreadcrumb
      }
    }
  }
`;

export default SubEventTemplate;
