import React from "react";
import * as styles from "./bottomNav.module.scss";
import { SanityLocaleString } from "../../../graphql-types";
import { usePageContext } from "../context/PageContext";
import { StyledLink } from "../styled/StyledLink";
import { slugify } from "../../utils/slugify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-light-svg-icons";

interface Props {
  next?: any;
  prev?: any;
  current: SanityLocaleString;
}

export const BottomNav: React.FC<Props> = ({ prev, next, current }) => {
  const { lang } = usePageContext();

  if (!next) return null;
  return (
    <nav className={styles.wrapper}>
      <StyledLink
        path={`/event/${slugify(prev?.fr)}`}
        className={styles.button}
      >
        <FontAwesomeIcon icon={faArrowLeft} className={styles.icon} />
        <div className={styles.button}>{prev?.[lang]}</div>
      </StyledLink>
      <div className={styles.title}>{current?.[lang]}</div>
      <StyledLink
        path={`/event/${slugify(next?.fr)}`}
        className={styles.button}
      >
        <div className={styles.button}>{next?.[lang]}</div>
        <FontAwesomeIcon icon={faArrowRight} className={styles.icon} />
      </StyledLink>
    </nav>
  );
};
