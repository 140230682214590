import React from "react";
import { SanityBlock } from "../../../graphql-types";
import { StyledLink } from "../styled/StyledLink";
import { PortableText } from "@portabletext/react";
import * as styles from "./selectionBlocks.module.scss";

interface Props {
  blocks: SanityBlock;
  centerDesktop?: boolean;
  centerButton?: boolean;
}
const serializers = {
  block: {
    header: (props: any) => {
      return <p className={styles.header}>{props.children}</p>;
    },
    background: (props: any) => (
      <p className={styles.withBackground}>{props.children}</p>
    ),
  },
  types: {
    separator: () => <div className={styles.separator}></div>,
  },
  marks: {
    Center: (props: any) => (
      <div className={styles.center}>{props.children}</div>
    ),
    link: (props: any) => (
      <StyledLink
        path={props.value.href}
        target={props.value.target}
        className={styles.link}
      >
        {props.children}
      </StyledLink>
    ),
    color: (props: any) => {
      return (
        <div
          style={{
            color: `rgba(${props?.value?.rgb?.r},${props?.value?.rgb?.g}, ${props?.value?.rgb?.b}, ${props?.value?.rgb?.a})`,
            display: "inline",
          }}
        >
          {props.children}
        </div>
      );
    },
  },
};

export const SelectionBlocks: React.FC<Props> = ({ blocks }) => {
  return (
    <div className={styles.wrapper}>
      {blocks && (
        <PortableText
          value={blocks}
          //   projectId={process.env.GATSBY_SANITY_PROJECT_ID || ""}
          //   dataset={process.env.GATSBY_SANITY_DATASET || "production"}
          components={serializers}
          //   className={styles.column}
        />
      )}
    </div>
  );
};
